/* Bonus.css */
.bonus-container {
  padding: 1rem;
}

.bonus {
  border-radius: 30px;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 3%;
  padding-top: 3%;
  display: flex;
  justify-content: space-between;
  background-color: #FF6767;
  border-radius: 30px 30px 0 0;
  margin-bottom: -1.5%;
}

.midcontent {
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  width: 100%;
  padding: 1rem; /* Add padding to the midcontent for better spacing */
  box-sizing: border-box; /* Ensure padding doesn't affect the width */
}

.midcontent h1 {
  margin-top: 0.8rem;
  margin-bottom: 0;
  font-weight: bold;
  font-family: "Roboto";
}

.midcontent button {
  width: 60%;
  margin-top: 1rem;
  padding: 1rem;
  background-color: #C84141;
  border-radius: 30px;
  border: 0;
  cursor: pointer;
  color: white;
}

.midcontent button:hover {
  background-color: pink;
  color: white;
}


@media (max-width: 4400px) {
  .bonus{
    border-radius: 10px;
  }
  .bonus img {
    width: 15vw;
    padding-bottom: 0.1%;
  }

  .midcontent {
    font-size: 2vw;
    width: 45%;
    padding-bottom: 2%;
  }

  .midcontent h1 {
    font-size: 3vw;
  }

  .midcontent button {
    width: 100%;
    font-size: 2vw;
    padding: 5%;
  }
}

