.introjs-tooltip {
  background-color: white;
  color: black;
  min-width: 80vw !important;
}

.introjs-tooltip-header {
  padding: 0;
}

.introjs-skipbutton {
  font-size: 25px;
  padding: 5px 10px 0;
}

.introjs-tooltiptext {
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  padding: 10px;
  padding-top: 0;
}

.introjs-arrow {
  /* position: absolute;
  right: 50%;
  left: 50%; */
  /* border-color: transparent transparent white !important; */
}

.introjs-tooltipbuttons {
  padding: 0;
}

.introjs-button {
  color: #df2800 !important;
  background-color: transparent !important;
  border: none !important;
}

.introjs-bullets {
  padding-top: 0;
}
